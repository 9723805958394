import useRequest, { CommonResponse, useRequestOptions } from "./../useRequest";
import { computed } from "vue";

export type useTransferRequestParams = {
  search?: string;
  page?: number;
  limit?: number;
};

const useTransferRequest = (
  getParams: () => useTransferRequestParams = () => ({ page: 1, limit: 20 }),
  options?: useRequestOptions
) => {
  const { data, ...other } = useRequest<CommonResponse<[]>>(
    "/app/finance/transfer/online-request",
    getParams,
    options
  );
  const enhancedData = computed(() => {
    if (!data.value) return null;
    return data.value.data;
  });

  return {
    data: enhancedData,
    ...other
  };
};

export default useTransferRequest;
