
import { computed, defineComponent, onBeforeMount, onMounted, reactive, ref, watch } from 'vue'
import moment from 'moment';

import TableSkeleton from "@/components/skeleton/Table.vue";
import Pagination from "@/components/Pagination.vue";
import useTransferRequest from '@/core/services/compositions/transfer/useTransferRequest';
import { showDeletePopup, showErrorPopup, showSuccessPopup, useMutateQuery, useQuery } from '@/core/helpers/common-helper';
import { useRouter } from 'vue-router';
import { ElNotification } from 'element-plus';
import { MenuComponent } from '@/assets/ts/components';
import { numberFormat } from "@/core/helpers/currency-helper";
import { checkPermission } from '@/router/authentication';
import ApiService from '@/core/services/ApiService';

export default defineComponent({
  components: {
    Pagination,
    TableSkeleton,
  },
  setup() {
    const router = useRouter();
    const { query } = useQuery();
    const dialogVisible = ref(false);
    const transfer = ref()
    const loadingOtp = ref(false)
    const loadingTransfer = ref(false)
    const submitButton = ref<null | HTMLButtonElement>(null);
    const dialogOTPVisible = ref(false);
    const transferCode = ref('');
    const otp1 = ref('');
    const otp2 = ref('');
    const otp3 = ref('');
    const otp4 = ref('');

    const form = reactive({
      source_account: '',
      destination_account: '',
      type: '',
      rc: '',
      status: '',
      date: [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
      order_by: 'created_at',
      order: 'DESC',
      ts: moment().format('YYYY-MM-DDTHH:mm:ss.SSS'),
    })

    const fetchParams = computed(() => ({
      page: query.value.page || 1,
      limit: query.value.limit || 20,
      date: query.value.date || form.date,
      source_account: query.value.source_account || form.source_account,
      type: query.value.type || form.type,
      rc: query.value.rc || form.rc,
      status: query.value.status || form.status,
      destination_account: query.value.destination_account || form.destination_account,
      order_by: 'created_at',
      order: 'DESC',
      ts: moment().format('YYYY-MM-DDTHH:mm:ss.SSS'),
    }));

    const { data, isValidating, mutate: refreshList, error } = useTransferRequest(() => fetchParams.value);

    watch(error, (error) => {
      if (error.response.status == 403) {
        router.push({ name: "403" });

        return;
      }
      ElNotification({
        title: error.response.data.rc,
        message: error.response.data.message,
        type: 'error',
      })
    });

    watch(query, (query) => {
      if (Object.keys(query).length === 0) {
        Object.assign(form, {
          source_account: '',
          destination_account: '',
          type: '',
          rc: '',
          status: '',
          date: [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
          order_by: 'created_at',
          order: 'DESC',
          ts: moment().format('YYYY-MM-DDTHH:mm:ss.SSS'),
        });
      }
    });

    const { mutateQuery } = useMutateQuery();
    const handleChangePage = (nextPage: number) => {
      mutateQuery({
        page: nextPage,
      });
    };

    const filtered = (filters) => {
      if (screen.width <= 760) {
        MenuComponent.hideDropdowns(undefined);
      }
      Object.assign(form, filters);

      mutateQuery({
        page: 1,
        date: form.date,
        source_account: form.source_account,
        destination_account: form.destination_account,
        type: form.type,
        rc: form.rc,
        status: form.status,
        order_by: 'created_at',
        order: 'DESC',
        ts: moment().format('YYYY-MM-DDTHH:mm:ss.SSS'),
      });
    };

    const showDetail = (data) => {
      dialogVisible.value = true

      transfer.value = data
    }

    const reject = (data) => {
      const payload = {
        transfer_code: data.transfer_code,
      };

      const params = {
        title: 'Warning',
        text: 'Reject transfer?',
        cancelButtonText: 'Cancel',
        confirmButtonText: 'Continue',
        errorMessage: (err: any) => 'Failed',
        action: () => ApiService.put(
          `app/finance/transfer-online/reject`,
          payload as any
        ),
        onSuccess: () => {
          mutateQuery({
            page: 1,
            date: form.date,
            source_account: form.source_account,
            destination_account: form.destination_account,
            type: form.type,
            rc: form.rc,
            status: form.status,
            order_by: 'created_at',
            order: 'DESC',
            ts: moment().format('YYYY-MM-DDTHH:mm:ss.SSS'),
          });
        }
      }
      showDeletePopup(params)
    }

    const checkOtp = () => {
      loadingOtp.value = true

      const payload = {
        transfer_code: transfer.value.transfer_code,
      };

      ApiService.setHeader();
      ApiService.post(
        `app/finance/transfer-online/approve`,
        payload as any
      )
        .then((resp) => {
          transferCode.value = resp.data.data.transfer_code_approver
          dialogVisible.value = false;
          dialogOTPVisible.value = true;
        })
        .catch(({ response }) => {
          if (Object.prototype.hasOwnProperty.call(response.data, "data")) {
            if (response.data.data.length > 0) {
              for (const key in response.data.data) {
                showErrorPopup({
                  title: response.data.rc,
                  text: response.data.data[key],
                  message: response.data.data[key],
                })
              }
            } else {
              showErrorPopup({
                title: response.data.rc,
                text: response.data.message,
                message: response.data.message,
              })
            }
          } else {
            showErrorPopup({
              title: response.data.rc,
              text: response.data.message,
              message: response.data.message,
            })
          }
        })
        .finally(() => {
          loadingOtp.value = false

          submitButton.value ? (submitButton.value.disabled = false) : null;
          submitButton.value?.removeAttribute("data-kt-indicator");
        });
    }

    const submitTransfer = () => {
      loadingTransfer.value = true
      dialogVisible.value = false;

      submitButton.value ? (submitButton.value.disabled = true) : null;
      submitButton.value?.setAttribute("data-kt-indicator", "on");

      const payload = {
        transfer_code: transfer.value.transfer_code,
        transfer_code_approver: transferCode.value,
        otp: `${otp1.value}${otp2.value}${otp3.value}${otp4.value}`
      };

      ApiService.setHeader();
      ApiService.post(
        `app/finance/transfer-online/approve`,
        payload as any
      )
        .then(() => {
          showSuccessPopup({
            title: 'Transfer Online',
            text: 'Transfer on process'
          });

          mutateQuery({
            page: 1,
            date: form.date,
            source_account: form.source_account,
            destination_account: form.destination_account,
            type: form.type,
            rc: form.rc,
            status: form.status,
            order_by: 'created_at',
            order: 'DESC',
            ts: moment().format('YYYY-MM-DDTHH:mm:ss.SSS'),
          });
        })
        .catch(({ response }) => {
          if (Object.prototype.hasOwnProperty.call(response.data, "data")) {
            if (response.data.data.length > 0) {
              for (const key in response.data.data) {
                showErrorPopup({
                  title: response.data.rc,
                  text: response.data.data[key],
                  message: response.data.data[key],
                })
              }
            } else {
              showErrorPopup({
                title: response.data.rc,
                text: response.data.message,
                message: response.data.message,
              })
            }
          } else {
            showErrorPopup({
              title: response.data.rc,
              text: response.data.message,
              message: response.data.message,
            })
          }
        })
        .finally(() => {
          dialogOTPVisible.value = false;
          loadingTransfer.value = false

          submitButton.value ? (submitButton.value.disabled = false) : null;
          submitButton.value?.removeAttribute("data-kt-indicator");
        });
    };

    const resetOtp = () => {
      otp1.value = ''
      otp2.value = ''
      otp3.value = ''
      otp4.value = ''
    }

    const terbilang = (a) => {
      const bilangan = ['', 'Satu', 'Dua', 'Tiga', 'Empat', 'Lima', 'Enam', 'Tujuh', 'Delapan', 'Sembilan', 'Sepuluh', 'Sebelas'];
      let kalimat = '';

      // 1 - 11
      if (a < 12) {
        kalimat = bilangan[a];
      }
      // 12 - 19
      else if (a < 20) {
        kalimat = bilangan[a - 10] + ' Belas';
      }
      // 20 - 99
      else if (a < 100) {
        const utama = a / 10;
        const depan = parseInt(String(utama).substr(0, 1));
        const belakang = a % 10;
        kalimat = bilangan[depan] + ' Puluh ' + bilangan[belakang];
      }
      // 100 - 199
      else if (a < 200) {
        kalimat = 'Seratus ' + terbilang(a - 100);
      }
      // 200 - 999
      else if (a < 1000) {
        const utama = a / 100;
        const depan = parseInt(String(utama).substr(0, 1));
        const belakang = a % 100;
        kalimat = bilangan[depan] + ' Ratus ' + terbilang(belakang);
      }
      // 1,000 - 1,999
      else if (a < 2000) {
        kalimat = 'Seribu ' + terbilang(a - 1000);
      }
      // 2,000 - 9,999
      else if (a < 10000) {
        const utama = a / 1000;
        const depan = parseInt(String(utama).substr(0, 1));
        const belakang = a % 1000;
        kalimat = bilangan[depan] + ' Ribu ' + terbilang(belakang);
      }
      // 10,000 - 99,999
      else if (a < 100000) {
        const utama = a / 100;
        const depan = parseInt(String(utama).substr(0, 2));
        const belakang = a % 1000;
        kalimat = terbilang(depan) + ' Ribu ' + terbilang(belakang);
      }
      // 100,000 - 999,999
      else if (a < 1000000) {
        const utama = a / 1000;
        const depan = parseInt(String(utama).substr(0, 3));
        const belakang = a % 1000;
        kalimat = terbilang(depan) + ' Ribu ' + terbilang(belakang);
      }
      // 1,000,000 - 	99,999,999
      else if (a < 100000000) {
        const utama = a / 1000000;
        const depan = parseInt(String(utama).substr(0, 4));
        const belakang = a % 1000000;
        kalimat = terbilang(depan) + ' Juta ' + terbilang(belakang);
      }
      else if (a < 1000000000) {
        const utama = a / 1000000;
        const depan = parseInt(String(utama).substr(0, 4));
        const belakang = a % 1000000;
        kalimat = terbilang(depan) + ' Juta ' + terbilang(belakang);
      }
      else if (a < 10000000000) {
        const utama = a / 1000000000;
        const depan = parseInt(String(utama).substr(0, 1));
        const belakang = a % 1000000000;
        kalimat = terbilang(depan) + ' Milyar ' + terbilang(belakang);
      }
      else if (a < 100000000000) {
        const utama = a / 1000000000;
        const depan = parseInt(String(utama).substr(0, 2));
        const belakang = a % 1000000000;
        kalimat = terbilang(depan) + ' Milyar ' + terbilang(belakang);
      }
      else if (a < 1000000000000) {
        const utama = a / 1000000000;
        const depan = parseInt(String(utama).substr(0, 3));
        const belakang = a % 1000000000;
        kalimat = terbilang(depan) + ' Milyar ' + terbilang(belakang);
      }
      else if (a < 10000000000000) {
        const utama = a / 10000000000;
        const depan = parseInt(String(utama).substr(0, 1));
        const belakang = a % 10000000000;
        kalimat = terbilang(depan) + ' Triliun ' + terbilang(belakang);
      }
      else if (a < 100000000000000) {
        const utama = a / 1000000000000;
        const depan = parseInt(String(utama).substr(0, 2));
        const belakang = a % 1000000000000;
        kalimat = terbilang(depan) + ' Triliun ' + terbilang(belakang);
      }

      else if (a < 1000000000000000) {
        const utama = a / 1000000000000;
        const depan = parseInt(String(utama).substr(0, 3));
        const belakang = a % 1000000000000;
        kalimat = terbilang(depan) + ' Triliun ' + terbilang(belakang);
      }

      else if (a < 10000000000000000) {
        const utama = a / 1000000000000000;
        const depan = parseInt(String(utama).substr(0, 1));
        const belakang = a % 1000000000000000;
        kalimat = terbilang(depan) + ' Kuadriliun ' + terbilang(belakang);
      }

      const pisah = kalimat.split(' ');
      const full = [''];
      for (let i = 0; i < pisah.length; i++) {
        if (pisah[i] != "") { full.push(pisah[i]); }
      }
      return full.join(' ');
    }

    onBeforeMount(() => {
      if (!checkPermission('transfer-online-maker').isGranted && !checkPermission('transfer-online-approver').isGranted) {
        router.push({ name: "app" });
      }
    })

    onMounted(() => {
      MenuComponent.reinitialization();
    });

    return {
      data,
      isValidating,
      handleChangePage,
      filtered,
      numberFormat,
      checkPermission,
      moment,
      showDetail,
      transfer,
      loadingOtp,
      submitButton,
      dialogOTPVisible,
      dialogVisible,
      checkOtp,
      submitTransfer,
      otp1,
      otp2,
      otp3,
      otp4,
      terbilang,
      resetOtp,
      loadingTransfer,
      reject,
    }
  }
})
